<template>

<div>
<img :src="showingImgSrc">
<canvas ref="imgCamvas" style="posotion:absolute; display:none;" >
</canvas>
<br>
<hr>
<select @change="selectImg"  v-model="selected" class="form-control"   >
    <option  v-for="img in imgList " v-bind:key="img" :value="img">
               {{ img.text }}
 </option>
 </select>
        
</div>

</template>

<script>

import moment from "moment";
import { fetchFileWithCache } from "../../modules/webapi/file";

export default {
  name: 'Image',
    props:[ "path"]  ,
  data (){
      return {
          showingImgSrc : "",
          selected : {src:"",text:""},
          imgList : [] 
      };
  },
  async mounted(  ) {
   
           await this.setImgList();
    },
    methods:{
  
       async selectImg (){
          
                 var d  = await fetchFileWithCache( this.selected.src );
                 const url =  d.base64.replace('data:application/octet-stream;','data:image/jpeg;');
             
                 var image = new Image();
                 image.onload = ()=>{
                        
                       const canvas = this.$refs.imgCamvas;
                       const ctx = canvas.getContext("2d");
                       // canvas.width =image.width;
                       // canvas.height =image.height;
                       ctx.drawImage(image , 0, 0);
                     this.showingImgSrc = url ;
                        
                    }
                image.src = url;
                
       },
       async setImgList(){
  
           await new Promise(resolve => setTimeout(resolve, 1000))
           const p=this.path.split('?');

           if(p.length !== 2 ){ return; }
      
           const param = new URLSearchParams(  "?" + p[1] );
 
         const name =param.get("name");
        const start = param.get("start");
        const end = param.get("end");
        
        var startDate = moment ( start ) ;
        var endDate = moment (end ) ;
        var loaded = false ;
    
        for (  ; startDate <= endDate ; ){

            try{
            var url = process.env.VUE_APP_API_URL + "GetDataFileListHttpTrigger"+"?dirname=image/" + name +  "/" + endDate.format('yyyy/MM/DD/') + ( process.env.VUE_APP_API_CODE.replace('?','&') ) ;

            const  res = await fetch(url);
            const t = JSON.parse( JSON.stringify(await res.json()));    
            
            if(t.filenames.length){
                    for ( var  d =t.filenames.pop() ; d ; d =t.filenames.pop() ) {
                         
                        d = d.split('/');
                        var fn = d.pop();
                        var dn = d.join('/');
                        var src =  process.env.VUE_APP_API_URL + "file/"+fn +"/?directory="+ dn +"/"+(process.env.VUE_APP_API_CODE).replace('?','&');
                        var text =  endDate.format('yyyy/MM/DD ') + fn ;
                        var opt = { src :src , text  :  text    } ;
                        
                        if (!loaded) {
                            this.selected = opt;
                            await this.selectImg();
                            loaded = true;
                        }
                        this.imgList.push( opt );
                }
            }
            }catch(e){
            
                console.log(e);
            }
            endDate.add(-1,'d');
        }
         
    }
    }
};

</script>