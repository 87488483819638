<template>
<iframe :style="style" ref="plateauview" :src="src+q">

</iframe>

</template>

<script>

export default {
  name: 'PlateauView',
    props:[ "path"]  ,
  data (){
      return {
          style: {width:"100%",height:"300px"},
          src : "",
          q : ""
      };
  },
  async created (){
         this.src = location.protocol + '//' + location.host+"/plateauview/"; 
         if(this.path){
           
            this.showData(this.path);
         }
  },
  async mounted(  ) {
   // this.showData(this.path);
    },
    methods:{
        showData ( path){

       try{

          if(!path){
              path =this.path;
          }
          path = encodeURI(path);
          path = decodeURI(path);
          path = path.replaceAll (  '+', '%2B' )
          const p=path.split('?');
          p.shift();
          
          var tmp ="?"+p.shift();
          p.map((d)=>{ tmp+="%3F"+(d.replace(/&/g, '%26')); });
         
         const param = new URLSearchParams(   tmp );
          
          const w = param.get('w') ;
          const h = param.get('h') ;
          if(w){
          this.style.width = w;
          }
          if(h){
            
            this.style.height = h ;
          }

          const particleSize  = param.get('particle_size')  ;
          const  aggregate  = param.get('agg');
          const startDate   = param.get('start')  ;
          const  endDate  = param.get('end');

          const initializationUrls = (param.get('initializationUrls'))?param.get('initializationUrls'):"" ;
          const temps =[]
          if(startDate){
             temps.push("startDate=" + startDate);
          }
          if(endDate){
             temps.push("endDate=" + endDate);
          }
          if(endDate){
             temps.push("endDate=" + endDate);
          }
          if(particleSize){
             temps.push("particleSize=" + particleSize);
          }
          if(aggregate){
             temps.push("aggregate=" + aggregate);
          }
          if(aggregate){
             temps.push("aggregate=" + aggregate);
          }
          if(initializationUrls){
            temps.push("initializationUrls=" + initializationUrls);
          }

          this.q = "?" + temps.pop();
          temps.map((v)=>{
            this.q +="&"+v;
          })
          
       
         }catch (e){
           console.log(e);  
         }
      }
    }
};

</script>
