

import moment from "moment";
import { setFetchAndloadToSqlite3 ,fetchAndloadToSqlite3Exec} from "../../modules/webapi/sensordata";
// import { Sql } from "../../modules/app/sql";
const _ = require('lodash');

export default {
  name: 'CustomDateSelector',
    props:["path"]  ,
  data (){
      return {
          visualization : {},
          isLoaded : false, 
          searchedKeys : {},
          settings : {},
          times :[
            {  value : "1min" , name: "1分"   },
         //  {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ],
          startDate : moment().format('yyyy-MM-DD') ,
          endDate: moment().add('d',1).format('yyyy-MM-DD'),

       hours : [
        { label : "0",
          value : "00" 
        },
        { label : "1",
          value : "01" 
        },
        { label : "2",
          value : "02" 
        },
        { label : "3",
          value : "03" 
        },
        { label : "4",
          value : "04" 
        },
        { label : "5",
          value : "05" 
        },
        { label : "6",
          value : "06" 
        },
        { label : "7",
          value : "07" 
        },
        { label : "8",
          value : "08" 
        },
        { label : "9",
          value : "09" 
        },
        { label : "10",
          value : "10" 
        },
        { label : "11",
          value : "11" 
        },
        { label : "12",
          value : "12" 
        },
        { label : "13",
          value : "13" 
        },
        { label : "14",
          value : "14" 
        },
        { label : "15",
          value : "15" 
        },
        { label : "16",
          value : "16" 
        },
        { label : "17",
          value : "17" 
        },
        { label : "18",
          value : "18" 
        },
        { label : "19",
          value : "19" 
        },
        { label : "20",
          value : "20" 
        },
        { label : "21",
          value : "21" 
        },
        { label : "22",
          value : "22" 
        },
        { label : "23",
          value : "23" 
        },
        { label : "24",
          value : "24" 
        }
        
       ],
        starthour : "00" ,
        endhour : "00"  
      };
  },
  async created(this:any){

      this.setPath(this.path);
     //  await this.showData(this.settings);
   },

    methods:{
       
        setPath (this:any ){
           
            
        if(this.path){

      
      const s =  this.path.split("?settings=")[1] ;
      if(s){
      try{ 
            
            this.settings = JSON.parse (s);
        }catch (e){
            //alert(e);
        try{ 
            
            const settings = window.decodeURIComponent(window.decodeURIComponent(window.decodeURIComponent(s)));
            this.settings = JSON.parse (settings);
      
          } catch (f) {
           
           try{ 
          
            const settings = window.decodeURIComponent(window.decodeURIComponent(s));
            this.settings = JSON.parse (settings);
      
            }catch (g){

                const settings = window.decodeURIComponent(s);
                this.settings = JSON.parse (settings);      
            }
        }
      }
       }

        this.presetData();      
      }
      
        },
      presetData(this:any){
              if (this.settings.vstartname!=""){
        
            if ( this.settings.startDate ){
                 this.startDate = this.settings.startDate;
                 
            } else {
                this.settings.startDate = this.startDate;
            }

            if ( this.settings.starthour ){
                 this.starthour = this.settings.starthour;
            } 

        }
        
        if ( this.settings.needEnd ){
            if (this.settings.vendname!=""){
                
                if ( this.settings.endDate ){
                
                 this.endDate = this.settings.endDate;
            } else {
                this.settings.endDate = this.endDate;
            }
            
            if ( this.settings.endhour ){
                 this.endhour = this.settings.endhour;
            } 

            }
            
        }

      },
      async showData (this:any , settings :any ){
    
          this.settings = settings ;
          await this.fetchData();
      },
    setVisualization (this:any , visualization:any ){

        this.visualization = visualization;
    },
    async set (this:any){
 

         
         if (this.settings.vstartname!=""){
        
            this.settings.startDate = this.startDate ;
              
            if ( this.starthour ){
                this.settings.starthour = this.starthour ;
            } 

        }
        
        if ( this.settings.needEnd ){
            if (this.settings.vendname!=""){
                
                
                 this.settings.endDate = this.endDate;
            
              if ( this.settings.endhour ){
                this.settings.endhour  = this.endhour ;
              } 

            }
            
        }

       // this.path= new URL( window.parent.location.href).pathname +"?settings="+JSON.stringify(this.settings);
        this.visualization.src = new URL( window.parent.location.href).pathname +"?settings="+JSON.stringify(this.settings) ;
        this.$emit('save', { isSafeSaveData :true });   
        //this.presetData();

        await this.fetchData();
    },
    getSettings (this:any){

        return this.settings ;
    },
    async fetchData(this:any  ){


    if( !this.settings  ){ return ; }

     const success = { "flash" :{  "message": "データを読み込みました。   描画には時間がかかる場合がございます。" ,"type": "success" }} ;
     
       this.Sql = await (window.parent.parent as any ).getSql();  
       
       
       var start = moment( this.startDate); 
       var end = moment(this.endDate);

       if ( this.settings.needEnd ){
        
            if (this.settings.vendname!=""){
                
                this.Sql.valiables[this.settings.vendname] = this.endDate;
                 
            }
            if ( this.endDate){
                 end = moment(this.endDate);
            }

            if (this.settings.vendhourname!=""){
                
                this.Sql.valiables[this.settings.vendhourname] = this.endhour;
            }
               
       }

       if (this.settings.vstartname!=""){
              
                this.Sql.valiables[this.settings.vstartname] = this.startDate;
            }


            if (this.settings.vstarthourname!=""){
                
                this.Sql.valiables[this.settings.vstarthourname] = this.starthour;
            }

       if( 1 >end.diff(start)){
           
                return   ;
                // { "flash" : {"message":"検索する日付をご確認して下さい。","type": "warning"}} ;   
       }
       

        const ids = Array.isArray( this.settings.sensorids) ?this.settings.sensorids : this.settings.sensorids.split(",") ;
        
        this.isLoaded = false;

        this.beforeStartDate =start;
        this.beforeEndDate =end;
        this.beforeSelectedAggregate = this.selectedAggregate;
     
         
        for (var i=0 ; i <  ids.length ;  i ++ ){
     
     //  await new Promise(s => setTimeout(s, 100)); 
          // if(!start ){continue;}
          // if(!end ){continue;}
   
          const node_id= ids[i];
        if(node_id ==""){ continue ;}
        
        if (this.settings.needRawData ){

           await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "",
	        false ,
	        false,
            true, 
            false);
            await fetchAndloadToSqlite3Exec();   
        }
       if (this.settings.needLatestData ){
            await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "",
	        false ,
	        false,
          false, 
            true);
            await fetchAndloadToSqlite3Exec();   
        }

        if ( this.settings.needFirstData ||this.settings.needAggregateData ){
            
            if(this.settings.granularity1min){
            await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "1min",
	        this.settings.needFirstData   ,
	        this.settings.needAggregateData ,
	        false,
          false );
            await fetchAndloadToSqlite3Exec();
            }

            if(this.settings.granularity10min){
            await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "10min",
	        this.settings.needFirstData   ,
	        this.settings.needAggregateData ,
	        false,
          false );
            await fetchAndloadToSqlite3Exec();
            }

            if(this.settings.granularity1hour){
            await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "1hour",
	        this.settings.needFirstData   ,
	        this.settings.needAggregateData ,
	        false,
          false);
            await fetchAndloadToSqlite3Exec();
            }
            
          }
        }

        this.isLoaded = true; 
        return success;
    },
	setSelectedParticleSize(this:any, d:string ){

        this.selectedParticleSize = d;
    },
    setSelectedAggregate(this:any, d:string ){

        this.selectedAggregate = d;
	}, setDateRange(this:any, start:string,end:string ){
			
			//startDate : moment().format('yyyy-MM-DD') ,
		if(start.includes('DaysBefore')){
		
				const num =Number(start.replaceAll("DaysBefore",""));
				this.startDate= moment().add('d',-num).format('yyyy-MM-DD');
		}
    },
    getStartDate(this:any){

        return this.startDate;
    },
    getEndDate(this:any){

        return this.endDate;
    },
    getParticleSize(this:any){

        return this.selectedParticleSize;
    },
    getAggregate(this:any){
        
        return this.selectedAggregate;
    },
    dateInputChanged ( this:any ){
  
        var now =  moment();
        if( now < moment( this.startDate) ){
            
            this.startDate = now.format('yyyy-MM-DD') ;
        }
        
        var tomm =  moment().add('d',1);
        if( tomm < moment( this.endDate) ){
            
            this.endDate = tomm.format('yyyy-MM-DD') ;
        }
       
        var diffDay = moment(this.endDate).diff (moment(this.startDate)) / (24*60*60*1000) ;
       
       if ( 1>diffDay){
            this.times = [ ];
        }
        if (1==diffDay){
            this.times = [
             {  value : "1min" , name: "1分"   },
         //   {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ];
        }
        if (1<diffDay){
            this.times = [
                 {  value : "1min" , name: "1分"   },
       //     {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ];
        }
        if (15<diffDay){
            this.times = [
             {  value : "1min" , name: "1分"   },
         //   {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ];
        }
       if ( 31<diffDay){
            this.times = [
            {  value : "1min" , name: "1分"   },
        //    {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ];
        }
    },        
    }
};

