

import moment from "moment";
import { setFetchAndloadToSqlite3 ,fetchAndloadToSqlite3Exec} from "../../modules/webapi/sensordata";
// import { Sql } from "../../modules/app/sql";

const _ = require('lodash');

export default {
  name: 'DateSelector',
    props:["sensorIDs","showGranularitySelector"]  ,
  data (){
      return {
          
          Sql :  undefined ,// ( window as any).Sql,
          isLoaded : false, 
          searchedKeys : {},
        //   searchedURLs : {},
          selectedParticleSize : "1hour" ,
          selectedAggregate : "median" ,
          //displayedTime : moment() ,
          times :[
            {  value : "1min" , name: "1分"   },
         //  {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ],
          startDate : moment().format('yyyy-MM-DD') ,
          endDate: moment().add('d',1).format('yyyy-MM-DD')
      };
  },
  
  async created(this:any){

    this.Sql = await  ( window as any ).getSql();  

     var useGranularity = {
            "1min":true,
            "5min":false,
            "10min":true,
            "1hour":true
        };
        if ( typeof this.showGranularitySelector === 'object' ){
            
            useGranularity = this.showGranularitySelector;
        }
         this.times = [];
        if (useGranularity["1min"]){
                this.times.push( {  value : "1min" , name: "1分"   });
        }
        if (useGranularity["5min"]){
                this.times.push( {  value : "5min" , name: "5分"   });
        }
        if (useGranularity["10min"]){
                this.times.push( {  value : "10min" , name: "10分"   });
        }
        if (useGranularity["1hour"]){
                this.times.push( {  value : "1hour" , name: "1時間"   });
        }
    
   },

    methods:{
    async fetchData(this:any, sensorids : any ,
        RequestAllAtOnce :boolean, 
        needFirstData :boolean ,
        needAggregateData:boolean ,
        needRawData: boolean,
        needLatestData:boolean

     ){

       const success = { "flash" :{  "message": "データを読み込みました。   描画には時間がかかる場合がございます。" ,"type": "success" }} ;
   

       var start = moment( this.startDate);
       var end = moment(this.endDate);
        
       if( 1 >end.diff(start)){
           
                return  { "flash" : {"message":"検索する日付をご確認して下さい。","type": "warning"}} ;   
       }
       
        const ids =  (sensorids) ? sensorids : this.sensorIDs ;
        this.isLoaded = false;

        this.beforeStartDate =start;
        this.beforeEndDate =end;
        this.beforeSelectedAggregate = this.selectedAggregate;
     

        if (RequestAllAtOnce){


            await setFetchAndloadToSqlite3 (  
	        ids ,
	        moment()  ,
	        moment().add(1,"d")  ,
	        this.selectedParticleSize,
	        needFirstData   ,
	        needAggregateData ,
	        needRawData ,
            needLatestData
            );
            

            
        }else{

        
        for (var i=0 ; i <  ids.length ;  i ++ ){
        //  await new Promise(s => setTimeout(s, 100)); 
          // if(!start ){continue;}
          // if(!end ){continue;}
   
            const node_id= ids[i];
        
            if(node_id ==""){ continue ;}
       
            await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        this.selectedParticleSize,
	        needFirstData   ,
	        needAggregateData ,
	        needRawData ,
            needLatestData
            );
            }
        }

        await fetchAndloadToSqlite3Exec();

        this.isLoaded = true; 
        return success;
    },
	setSelectedParticleSize(this:any, d:string ){

        this.selectedParticleSize = d;
    },
    setSelectedAggregate(this:any, d:string ){

        this.selectedAggregate = d;
	}, setDateRange(this:any, start:string,end:string ){
			
			//startDate : moment().format('yyyy-MM-DD') ,
		if(start.includes('DaysBefore')){
		
				const num =Number(start.replaceAll("DaysBefore",""));
				this.startDate= moment().add('d',-num).format('yyyy-MM-DD');
		}
    },
    /*
    setSensorIDs(this:any, ids:string ){

        this.sensorIDs = ids.split(',');
    },*/
    getStartDate(this:any){

        return this.startDate;
    },
    getEndDate(this:any){

        return this.endDate;
    },
    getParticleSize(this:any){

        return this.selectedParticleSize;
    },
    getAggregate(this:any){
        
        return this.selectedAggregate;
    },
    dateInputChanged ( this:any ){
  
        var now =  moment();
        if( now < moment( this.startDate) ){
            
            this.startDate = now.format('yyyy-MM-DD') ;
        }
        
        var tomm =  moment().add('d',1);
        if( tomm < moment( this.endDate) ){
            
            this.endDate = tomm.format('yyyy-MM-DD') ;
        }
       
       
        var diffDay = moment(this.endDate).diff (moment(this.startDate)) / (24*60*60*1000) ;
       

        var useGranularity = {
            "1min":true,
            "5min":false,
            "10min":true,
            "1hour":true
        };
        if ( typeof this.showGranularitySelector === 'object' ){
            
            useGranularity = this.showGranularitySelector;
        }

        

       if ( 1>diffDay){
            this.times = [ ];
        }
        if (1==diffDay){
            this.times = [];
            if (useGranularity["1min"]){
                this.times.push( {  value : "1min" , name: "1分"   });
            }
            if (useGranularity["5min"]){
                this.times.push( {  value : "5min" , name: "5分"   });
            }
            if (useGranularity["10min"]){
                this.times.push( {  value : "10min" , name: "10分"   });
            }
            if (useGranularity["1hour"]){
                this.times.push( {  value : "1hour" , name: "1時間"   });
            }
        }
        if (1<diffDay){

            this.times = [];
            if (useGranularity["1min"]){
                this.times.push( {  value : "1min" , name: "1分"   });
            }
            if (useGranularity["5min"]){
                this.times.push( {  value : "5min" , name: "5分"   });
            }
            if (useGranularity["10min"]){
                this.times.push( {  value : "10min" , name: "10分"   });
            }
            if (useGranularity["1hour"]){
                this.times.push( {  value : "1hour" , name: "1時間"   });
            }

        }
        if (15<diffDay){
            
            this.times = [];
             if (useGranularity["1min"]){
                this.times.push( {  value : "1min" , name: "1分"   });
            }
            if (useGranularity["5min"]){
                this.times.push( {  value : "5min" , name: "5分"   });
            }
           
            if (useGranularity["10min"]){
                this.times.push( {  value : "10min" , name: "10分"   });
            }
            if (useGranularity["1hour"]){
                this.times.push( {  value : "1hour" , name: "1時間"   });
            }

        }
       if ( 31<diffDay){
            this.times = [];
             if (useGranularity["1min"]){
                this.times.push( {  value : "1min" , name: "1分"   });
            }
            if (useGranularity["5min"]){
                this.times.push( {  value : "5min" , name: "5分"   });
            }
           
            if (useGranularity["10min"]){
                this.times.push( {  value : "10min" , name: "10分"   });
            }
            if (useGranularity["1hour"]){
                this.times.push( {  value : "1hour" , name: "1時間"   });
            }

        }
    },        
    }
};

