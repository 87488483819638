

import { defineComponent,nextTick } from "vue";

import Metric from "../../../components/chart/Metric.vue";

import CustomDateSelector from "../../../components/chart/CustomDateSelector.vue";

import JSField from "../../../components/chart/JSField.vue";

import PlateauView from "../../../components/chart/PlateauView.vue";

import Image from "../../../components/chart/Image.vue";

import ImageV4 from "../../../components/chart/ImageV4.vue"

import DateSelector from "../../../components/chart/DateSelector.vue";

import Map from "../../../components/chart/Map.vue";

import moment from "moment";

import Tagify from '@yaireo/tagify'

import '@yaireo/tagify/dist/tagify.css';

//  import { Sql } from "../../../modules/app/sql"; // const _ = require("lodash")
const Compression = require("../../../modules/app/compression.js");

export default defineComponent({
  name: "CustomDashboard",
    components:  { Map ,JSField,Metric,Image,ImageV4,DateSelector,PlateauView,CustomDateSelector},
  data(){
      return {
     css : `data:text/css;base64,${Buffer.from( "" , 'utf-8').toString('base64')}`,
    tagifys : [] as any,
    longPressTimer : null as any,
         dragStartIndex: null,
         selectedEditTamplateName :"",
         inLoading : false ,
          editModeLoop :undefined ,
          selectedEditView : {},
          allowList:"",
          tabs : [] ,
          refs: [],
          sensorIDs : "" ,
          viewTemplates : [ { 
              "template_type": "line",
             "template_name" : "線グラフ",
             "type": "iframe",
            "src" : "/pwa/#/chart/line/?start={START_DATE}&end={END_DATE}&agg={AGGREGATE}&particle_size={PARTICLE_SIZE}",
            "class": ["col-12"],
		    "style": "height:700px; display:inline-block; padding:1%;"
          },{ 
           "template_type": "metric",
            "template_name" : "メトリック",
              "type": "metric",
            "scrolling": "no",
			 "style": "overflow:hidden;",
            "src" : "/pwa/#/chart/metric/?start={START_DATE}&end={END_DATE}&particle_size={PARTICLE_SIZE}&agg={AGGREGATE}",
           },{ 
            "template_type": "image",
            "template_name" : "画像",
             "type": "image",
             "scrolling": "no",
            "src" : "/pwa/#/chart/image/?start={START_DATE}&end={END_DATE}",
            "style": "overflow:hidden; height:450px; display:inline-block; padding:1%;"
          },{ 
            "template_type": "imagev4",
            "template_name" : "画像v4",
             "type": "imagev4",
             "scrolling": "no",
            "src" : "/pwa/#/chart/imagev4/?start={START_DATE}&end={END_DATE}",
            "style": "overflow:hidden; height:450px; display:inline-block; padding:1%;"
          },
          { 
            "template_type": "map",
            "template_name" : "地図",
             "type": "map",
             "scrolling": "no",
            "src" : "/pwa/#/chart/map/?start={START_DATE}&end={END_DATE}&agg={AGGREGATE}&particle_size={PARTICLE_SIZE}",
            "style": "overflow:hidden; height:450px; display:inline-block; padding:1%;"
          },{ 
            "template_type": "jsfield",
            "template_name" : "スクリプト",
            "type": "jsfield",
            "scrolling": "no",
			"style": "overflow:hidden;",
            "src" : "/pwa/#/chart/jsfield/?start={START_DATE}&end={END_DATE}&particle_size={PARTICLE_SIZE}&agg={AGGREGATE}",
           },{ 
            "template_type": "plateauview",
            "template_name" : "PLATEAUView",
            "type": "plateauview",
            "scrolling": "yes",
			"style": "overflow:hidden;",
            "src" :  "/pwa/#/chart/plateauview/?start={START_DATE}&end={END_DATE}&particle_size={PARTICLE_SIZE}&agg={AGGREGATE}",
           },{ 
           "template_type": "customdateselector",
            "template_name" : "CustomDateSelector",
              "type": "customdateselector",
            "scrolling": "no",
			 "style": "overflow:hidden;",
            "src" : "/pwa/#/chart/customdateselector/",
           },{ 
              "template_type": "iframe",
             "template_name" : "IFrame",
             "type": "iframe",
            "src" : "",
            "class": ["col-12"],
		    "style": "height:700px; display:inline-block; padding:1%;"
          }
          ] ,
                   addingTabName :"空のタブ",
          editingViewTitle : "<h3>空のタイトル</h3>",
          editingViewPosition : "top",
          showEditMode : false ,
          useEditMode : false ,
          
          editViewURL : "" ,
          editViewURLPath :"",
          editViewQuery : "",
          
          currentTabNum:-1 ,
          setting : {},
          showDateSelector : true , 
          showGranularitySelector : true ,
          showAggregateSelector : false ,  
          showParticleSizeSelector : false ,
          startDate : "" ,
          endDate : "",
		  urlObserver : undefined ,
		  menu : {css:""} 
      }
  },
  beforeMount(){
  },
  beforeCreate(){

  },
  async  created(this:any){
  
        this.$emit('show-header'); 
        this.menu =this.$parent.$parent.dashboard.menu;
        

         }, 
    watch:{
    showEditMode (this: any ,newValue:any) {
      
      this.handleTagfiy(newValue);
    }


    },

   async mounted (this:any){

    await this.initializeComponent(this.$route.path);
   },
   async beforeRouteUpdate (this:any ,to:any 
   //, from:any, next:any
   ){
       

        this.showEditMode=false;
       
        this.handleTagfiy(false );
        await this.initializeComponent(to.path);
   },
   
   unmounted (this:any){

   //   this.urlObserver.disconnect();
  } ,
  methods :{ 
    onEditCSS(this:any){

        this.css = `data:text/css;base64,${Buffer.from( "" , 'utf-8').toString('base64')}`;
        this.$emit('show-edit-css',{ show:true , path : this.$route.path });

    },
    handleTagfiy(this:any,isshow:boolean){


     this. tagfiyDestroyIfnecessary();
     if (!isshow){return; }

     for( let i=0; i < this.$refs.pkeys.length ; i++){
        
        const tabnum =this.$refs.pkeys[i].id.split('-').pop();
        const t = new Tagify(this.$refs.pkeys[i], {
          maxTags: 100,
          dropdown: {
            enabled: 0
          }
        });
        
        let v = this.setting.tabs[tabnum].sensorids;
        
        if (typeof this.setting.tabs[tabnum].sensorids == 'string'){

            v = JSON.parse(this.setting.tabs[tabnum].sensorids);
        }

        this.$refs.pkeys[i].addEventListener('change', this.changeSensorIDs);
        v = v.map((d:any)=> d );
        t.addTags(v);   
        this.tagifys.push (t) ;
          
     }
        

   },
   tagfiyDestroyIfnecessary(this:any){

     if (this.tagifys.length) {

            for( let i=0; i < this.tagifys.length ; i++){
                
                try{
                
                    this.tagifys[i].destroy();
                this.$refs.pkeys[i].removeEventListener('change',this.changeSensorIDs)
                
                }catch (e:any){ console.error(e); }
                
                this.tagifys.splice(i, 1);
                i--;
            }
        }  
   },
    async initializeComponent(this:any,path:any ){

         this.menu =this.$parent.$parent.dashboard.menu;

    window.addEventListener("orientationchange", ()=> {
	    var elements:any = window.document.getElementsByClassName("d-view-iframe");
            while(elements.length > 0){
                 try{ 
                const src =  elements[0].src;
                 elements[0].src =  src +"&c";
              }catch (e:any) { console.error(e);}
          }
    });

        const menu =this.menu;
             

            // test end    ;
		    for( var i=0 ; i <  menu.length ; i++ ){
            if(menu[i].path && menu[i].path == path ){
                
                if (
                    this.$route.params.edit == "css" 
                    ||
                     !this.menu[i].css) {
               
                    this.menu[i].css = ""; }


                this.css = `data:text/css;base64,${Buffer.from( this.menu[i].css , 'utf-8').toString('base64')}`;
   

                this.setting = menu[i];
               
               if ( this.setting.tabs){
                this.setting.tabs.map( (tab:any)=>{

                    tab.visualizations.map( (view:any)=>{
                        try{
                        if(!view.position){
                            view.position = "bottom";
                        }}catch (e){ console.error(e); }
                    });
                });
                }
                
                if( menu[i]["date-search-show-granularity"] != undefined  ){

                   this.showGranularitySelector = menu[i]["date-search-show-granularity"];
                }
                if(menu[i]["sensorids"]){

                   if(menu[i]["sensorids"].length != 0 ){
                     this.sensorIDs = menu[i].sensorids;
                    }
                }
                  if( ( menu[i] as any )["allowlist"]  ){
                    
                    this.allowList = (menu[i] as any).allowlist;
                }
                if(  (menu[i] as any)["tab-position"] ){
                    
                    this.tabPosition = ( menu[i] as any)["tab-position"];
                } 
                if(menu[i]["editmode"]){
                    
                    this.useEditMode = true;
                }
                if(menu[i]["date-search"]){
                    
                    this.showDateSelector = true;
                }
                if(menu[i]["aggregate"]){
                    
                    this.showAggregateSelector = true;
                }
                if(menu[i]["particlesize"]){
                    
                    this.showParticleSizeSelector = true;
                }
                if(menu[i]["initial-particlesize"]){
                
                    this.$refs.dateselector.setSelectedParticleSize( menu[i]["initial-particlesize"] );
                }
                if(menu[i]["initial-aggregate"]){
                    
                    this.$refs.dateselector.setSelectedAggregate( menu[i]["initial-aggregate"]);
                }
                if(menu[i]["initial-date-range"] ){
                    
                    this.$refs.dateselector.setDateRange( menu[i]["initial-date-range"]);
                }
                
            }
        } 
        this.currentTabNum=0;
     // await this. updateViewAndFetchData();
        await this.changeView (); 
    },
   async addRef (this:any, el:any,view:any ,name :string , tabNum:any , vNum :any ) {
    
        this.refs [ name + "_"+tabNum + "_" + vNum ] = el ;

       // el.setPath(view.src);
        el.setVisualization( this.setting.tabs[tabNum].visualizations[vNum] );
       // await el.fetchData();
    },
    startCardDrag(this:any , index:any) {
     if ( !this.showEditMode ){return; }
      this.dragStartIndex = index;
    },
    endCardDrag(this:any,index:any,tab:any) {
    
    if ( !this.showEditMode ){return; }


      if (this.dragStartIndex !== null && this.dragStartIndex !== index) {
        // 要素の位置を交換
        // const tab = this.setting.tabs[this.currentTabNum];
        const itemToMove = tab.splice(this.dragStartIndex, 1)[0];
        tab.splice(index, 0, itemToMove);
        
       const stab = this.setting.tabs[this.currentTabNum].visualizations;
        const sitemToMove = stab.splice(this.dragStartIndex, 1)[0];
        stab.splice(index, 0, sitemToMove);
              
      }
      this.dragStartIndex = null;
    },
    
   /* changeDefaultRangOfDate (this:any) {
      
      this.setting.tabs[this.currentTabNum].defaultRangOfDate   = this.setting.tabs[this.currentTabNum].defaultRangOfDate;

   },*/
    changeSensorIDs( this:any , e:any ) {
       const r  = JSON.parse( e.target.value).map( (item :any) => item.value);
       this.setting.tabs[this.currentTabNum].sensorids = r;
       
       // this.setting.tabs[this.currentTabNum].sensorids.split(',');
       //this.$refs.dateselector.setSensorIDs(this.sensorIDs);    
    }, 
   
   replaceParamToday(str:string){
        const d = str.match(/\+(.+)D/) ;
        if(d===null){

           return moment().format("YYYY-MM-DD");   
        }
        
        return moment().add(Number(d[1]),'d').format("YYYY-MM-DD");
    },
    addEditViewQuery(this:any,text :string){

            return (this.editViewQuery) ? text + '&'+ this.editViewQuery : text; 
    },
    replacePathParamOnece( this:any, p:string ){
      
      var path = p ; // str copy 
//https://192.168.5.131:1024/api/v2/devices/00055405/data?start=2022-05-18&end=2022-05-19&agg=median&continuationToken=undefin

        path = path.replace("{START_DATE}", this.$refs.dateselector.getStartDate() );
        path = path.replace("{END_DATE}",this.$refs.dateselector.getEndDate());   
        path = path.replace("{PARTICLE_SIZE}",  this.$refs.dateselector.getParticleSize() );
        path = path.replace("{TEMPORAL_GRANULARITY}",  this.$refs.dateselector.getParticleSize() );

        path = path.replace("{AGGREGATE}",  this.$refs.dateselector.getAggregate() );   
        path = path.replace("{TODAY}",  moment().format('YYYY-MM-DD') );   
        path = path.replace("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
  
        return path;
    },
    replacePathParam( this:any, p:string ){
      
      var path = p ; // str copy 
//https://192.168.5.131:1024/api/v2/devices/00055405/data?start=2022-05-18&end=2022-05-19&agg=median&continuationToken=undefin

        path = path.replaceAll("{START_DATE}", this.$refs.dateselector.getStartDate() );
        path = path.replaceAll("{END_DATE}",this.$refs.dateselector.getEndDate());   
        path = path.replaceAll("{PARTICLE_SIZE}",  this.$refs.dateselector.getParticleSize() );
        path = path.replaceAll("{TEMPORAL_GRANULARITY}",  this.$refs.dateselector.getParticleSize() );
        
        path = path.replaceAll("{AGGREGATE}",  this.$refs.dateselector.getAggregate() );   
        path = path.replaceAll("{TODAY}",  moment().format('YYYY-MM-DD') );   
        path = path.replaceAll("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
  
        return path;
    },
    onEditMode(this:any, b:boolean){
      this.showEditMode  =b;
      
      if( this.showEditMode){
          
          var a = (new URLSearchParams(window.location.search)).get('chartSql');
          this.editModeLoop = setInterval(()=>{
              const b = (new URLSearchParams(window.location.search)).get('chartSql');
             if(a  !== b ){
                 a = b;
                 this.reloadViewAll();
                }
          },2000)
      } else{
          clearInterval(this.editModeLoop );
      }
      
    },
    clearViewAll (){
       
      var elements:any = window.document.getElementsByClassName("d-view-iframe");
      while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
      elements = window.document.getElementsByClassName("d-view-section");
      while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
      elements = window.document.getElementsByClassName("d-view-article");
      while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
        elements = window.document.getElementsByClassName("d-view");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    },

    async compressSrc (this:any , src:string ){

        const srcs = src.split('?');
        
        const sr = srcs.shift();
        if ( srcs.length  ){

            var param = "";
            for (let i=0; i < srcs.length ; i++  )
            {
                param += srcs[i]; 
            }
            const c = await Compression.Compression.compressString(param );
            return sr +"?c="+c;
        } 
        return src;
         
    },

     async createTagString ( this:any , obj :any , i: string ){

           var html = "<div class='d-view col-12'>" ;     
           
            html += "<article class='d-view-area' >";
            const  src = 
            await this.compressSrc (
                
             //   (obj.type === 'iframe')? 
              
              //  this.replacePathParam( obj.src ).replaceAll ( '%','%25' ).replaceAll ( '+','%2B' ) : 
                this.replacePathParam( obj.src ) 
            );
          const param = ( src.includes('?') ? '&' : '?' ) + 'iFrameId=' + ( this.setting.path.replace(/\//g, "_").replace(/-/g, '_') + '_' + i + '_' + moment().unix() ).trim() + '" ' +   ((obj.scrolling ==='no')? 'scrolling="no"' : '') ;
      // sandbox="allow-downloads allow-same-origin allow-scripts " cors 設定時には document.domain が利かなくなる為
            html +=  '<section d-view-section style="'+obj.style+'" class="'+obj.class+'">' + obj.title + '<iframe  class="col-12 d-view-iframe card" loading="lazy" style="height:88%; width:100%;"  src="' + src  + param +'></iframe></section>' ;
            
           html += "</article>" ;   
          return html; 
    },
    changeSelectedEditView( this: any, event:any){

        for ( const key in this.viewTemplates ){

            const template:any = this.viewTemplates[key];
            if ( (template as any ) .template_name == event.target.value ){

                    this.selectedEditView = JSON.parse(JSON.stringify(template));
                    // this.selectedEditView.template_name = event.target.value;                       
            }

        }
    },
    async addView( this: any ){

        var o =JSON.parse(JSON.stringify(this.selectedEditView));
          
          o.title = this.editingViewTitle; 
          o.position = this.editingViewPosition;
        
          const param = new URLSearchParams(window.location.search);
            
            if(param.has("ec")){
            
                o.src += "&q="+ await Compression.Compression.decompressString( param.get("ec"));
          
            } else {
            if(param.has("chartSql")){
            
                o.src += "&q="+param.get("chartSql");

            } else if(param.has("settings")){
            
                if (o.src.includes('?')){
                    o.src += "&settings="+param.get("settings");
                }else{
                o.src += "?settings="+param.get("settings");
                }

            } 
            else if(param.has("q")) {

                o.src += "&q="+param.get("q");    
            }

            if(param.has("s")) {
                const s = param.get("s");
                o.src += "&s=" + s?.replaceAll('&','%26');  
               }
            if(param.has("w")) {

                o.src += "&w="+param.get("w");    
            }
            if(param.has("h")) {

                o.src += "&h="+param.get("h");    
            }
            if(param.has("initializationUrls")) {

                o.src += "&initializationUrls="+param.get("initializationUrls");    
            }

            if( this.editViewQuery){
              
                o.src += "&"+ this.editViewQuery.replace(/\r?\n/g, ' ');
             }

             o.src = o.src.replaceAll('%0A','').replaceAll('%0D','');
            }
             
      
       this.setting.tabs[this.currentTabNum].visualizations.push(  o );    


             if ( o.type==='iframe'  ){

                       var html = await this.createTagString ( o  , this.currentTabNum ) ;

                      this.tabs[this.currentTabNum].push( { type : o.type , html : html ,position: o.position} );
        
            } else if ( o.type==='metric' ){
                   
                  
                   const src = await this.compressSrc( this.replacePathParam( o.src ) );
                  
                      this.tabs[this.currentTabNum].push ( {  type : o.type ,  title : o.title , src: src  , position: o.position } );
            }else if ( o.type==='jsfield' ){
                
                      this.tabs[this.currentTabNum].push ( {  type : o.type ,  title : o.title , src: this.replacePathParam(  o.src.replace(/\r?\n/g, '')  ) , position: o.position } );
            } else if ( o.type==='image' ){
                   
                     this.tabs[this.currentTabNum] .push ( {  type : o.type , title : o.title , src: this.replacePathParam(  o.src.replace(/\r?\n/g, '')  ) , position: o.position} );
             } else if ( o.type==='imagev4' ){
                   
                     this.tabs[this.currentTabNum] .push ( {  type : o.type , title : o.title , src: this.replacePathParam(  o.src.replace(/\r?\n/g, '')  ) , position: o.position} );
             } else if ( o.type==='map' ){
                   
                     this.tabs[this.currentTabNum] .push ( {  type : o.type , title : o.title , src: this.replacePathParam(  o.src.replace(/\r?\n/g, '')  ), position: o.position} );
            } else if ( o.type==='plateauview' ){
                   
                     this.tabs[this.currentTabNum] .push ( {  type : o.type , title : o.title , src: this.replacePathParam(  o.src.replace(/\r?\n/g, '')  ) , position: o.position} );
             }else if ( o.type==='customdateselector' ){

              
this.tabs[this.currentTabNum] .push ( {  type : o.type , title : o.title , src: this.replacePathParam(  o.src.replace(/\r?\n/g, '')  ) , position: o.position} );   
    await this.fetchWithCustomDate(
       async ()=>{});
                 
             
             }
    },
     copyEdit (this:any, ti :number , vi :number  ){ 
        
        const keys = Object.keys ( this.setting.tabs[ti].visualizations[vi]);
        
        for ( var i=0 ;  i < keys.length ; i++ ){
           const key = keys[i]; 
       
            this.selectedEditView[key] = this.setting.tabs[ti].visualizations[vi][key];
        
            if(key=="template_name"){
              
              this.selectedEditTamplateName =  this.setting.tabs[ti].visualizations[vi][key];
                
            }else  if(key=="title"){
               
               this.editingViewTitle =  this.setting.tabs[ti].visualizations[vi][key];
            }
            
        }

        this.selectedEditView.src=this.selectedEditView.src.replaceAll ( '%','%25' ).replaceAll ( '+','%2B' );
        var path = this.replacePathParamOnece(this.addEditViewQuery(this.selectedEditView.src));
        
        /* var params = new URLSearchParams( path );

        this.selectedEditView.src =path;
        const q:any =params.get('q');
        
        params =new URLSearchParams( this.replacePathParam(this.addEditViewQuery( path)));
        params.set('q',  q );

        var query=params.toString().split('%3F');
        query.shift();
        
        path = path.split('?')[0] + '?' + query.join('%3F'); 
        this.editViewURL =   path;   //this.selectedEditView.src.replaceAll ( '%','%25' ).replaceAll ( '+','%2B' );
        */
        this.editViewURL =   path;
        // this.selectedEditView.src =path;


      //   this.showEditMode=true;
    },
    deleteView(this:any, ti :number , vi :number  ){
        const v = [];    
        for ( var j=0; j < this.setting.tabs[ti].visualizations.length ; j++ ){
            if( j !==vi  ){  v.push(   this.setting.tabs[ti].visualizations[j] );}
        } 
        this.setting.tabs[ti].visualizations =  v;
     
        const sv = [];    
        for ( var k=0; k < this.tabs[ti].length ; k++ ){
            if( k !==vi  ){ sv.push(  this.tabs[ti][k] ); }
        } 
        this.tabs[ti] =  sv;
     
    } ,
    deleteTab(this:any, i :number ){
       
        const tabs = [];    
        for ( var j=0; j < this.setting.tabs.length ; j++ ){
            if( j !==i  ){
                
                tabs.push(  this.setting.tabs[ j ] );
            }
        } 
        this.setting.tabs =  tabs;
 
         const temptabs:any = [];    
        for ( var k=0; k < this.tabs.length ; k++ ){
            if( k !==i  ){
                temptabs.push(  this.tabs[ k ] );
            }
        } 
         this.tabs =  temptabs;
    },

   async fetchWithCustomDate(this:any, func :any ){

   const settingList = [];
            for ( let i=0 ; i <  this.setting.tabs[this.currentTabNum]  .visualizations.length ; i ++ ){
            
                const visualization = this.setting.tabs[this.currentTabNum]  .visualizations[i];
                
                 if ( visualization.template_type =="customdateselector"){
                    
                   
          const s = visualization.src.split("settings=")[1];
          
     var settings:any;
      try{ 

            settings = JSON.parse (s);

        }catch (e){
        
        try{ 
            
             settings = window.decodeURIComponent(window.decodeURIComponent(window.decodeURIComponent(s)));
             settings = JSON.parse (settings);
      
          } catch (f) {
           
           try{ 
          
             settings = window.decodeURIComponent(window.decodeURIComponent(s));
            settings = JSON.parse (settings);
      
            }catch (g){

                settings = window.decodeURIComponent(s);
                settings = JSON.parse (settings);      
            }
          }
        }
        
        if (settings){
          
        settingList.push(settings);
                }/*
                   const ref = this.refs[ "customdateselector_"+this.currentTabNum +"_" + i ];
                   if ( ref ){ 
                        
                        await ref.setVisualization( visualization);
                        await ref.fetchData();
                       
                   }
                   */
                }
            }


     this.$emit('fetch-custom-data', { settings :settingList , done : async () =>{ await func(); } } );


   },
   async changeView (this:any){


       
this.fetchWithCustomDate(
                 async ()=> {
                
       if ( this.setting.tabs[this.currentTabNum].defaultRangOfDate
          && this.setting.tabs[this.currentTabNum].defaultTables
       ){
        this.$emit('fetch-data', { 
            prefetch : { 
                sensor: { 
                  //  async : true ,
                    granularity :this.setting.tabs[this.currentTabNum].defaultTables.split(',') , 
                    periodofday: this.setting.tabs[this.currentTabNum].defaultRangOfDate , 
                    sensors:this.setting.tabs[this.currentTabNum].sensorids
                }
            }  , 
            done : async () => {
           await this.updateViewAndFetchData ()            
            
            } 
        });

            //ここで CustomDateSelector 全て読み出す。
            // viewの設定を登録する。
            // 更新ボタン
          
          
       }  else {
    
           await this.updateViewAndFetchData (); 
       }  
        } 
                );

    },
    async addTab(this:any){
       
        await this.updateViewAndFetchData (); 
        const o = {
            name : this.addingTabName ,
            visualizations  : [] ,
            sensorids : [],
            needAggregateData :true,
            needFirstData : false,
            needRawData : false,
            needLatestData : false,
            RequestAllAtOnce : false
        } ;
        this.setting.tabs.push(o); 
        this.tabs.push([]);
        
        this.currentTabNum =  this.setting.tabs.length-1;
       
      
       // this.updateViewAndFetchData( );

    },
    async saveViews (this:any,  param :any ){
    
       var o =  {
      "menu-title":  (this.setting['menu-title'])?this.setting['menu-title']:""  ,
      "title": (this.setting.title)?this.setting.title:"" ,
      "path": "",
      "editmode": true , 
      "date-search": true,
      "aggregate": true,
      "particlesize": true,
      "initial-particlesize": "1hour",
      "initial-aggregate": "median",
      "sensorids" : this.sensorIDs, 
      "tabs":[]
          } as any;
    
       if(this.setting.firstview){
        
            o.firstview = this.setting.firstview;
       }

         let  self ;
         if ( ( window as any ) .isCrossOrigin  ){
            
            self = window.parent;
         } else {
            self = window ;
         }

         self.history.pushState( {}, '', new URL( self.location.href).pathname ) ;  
         
        var title = window.location.pathname.split("/").pop() ; 
        const p = window.location.pathname.split('/');
        
        p.shift(); p.shift();
        o.path = "/"+p.join('/');
        
        o.tabs = this.setting.tabs;
        
        
       try{
       var data = JSON.stringify(o);

       // const blob = new Blob([ window.btoa( window.encodeURIComponent( data.replace(/\r?\n/g, ''))) ],{type:"text/plain"});
       // const formData = new FormData();
       // formData.append('menu', blob);


    const param  = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
            },
        body:  data
    };

    const res = await fetch( process.env.VUE_APP_APP_API_V4_URL + "dashboard/setting/menu" , param)
        if(res.ok){ 
        
            this.$emit('show-flash',{ "message":"保存しました。","type": "success"}); 
            return ;
        }
    }catch(e){  console.error(e); }
  
   if (param.isSafeSaveData){
    this.$emit('show-flash',{ "message":"保存に失敗しました。設定ファイルをアップロードしてください。","type": "success"});
    
    let dashboard = this.$parent.$parent.dashboard;
    for( var i=0 ; i <  dashboard.menu.length ; i++ ){
              
        if(dashboard.menu[i].path && dashboard.menu[i].path == this.$route.path ){
            dashboard.menu[i]=o;
        }
    }

    const blo = new Blob([  JSON.stringify(dashboard) ],{type:"text/plain"});
    
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blo)
    link.download = 'menu.palettejson'
    link.click();
        } else {

         this.$emit('show-flash',{ "message":"設定の保存に失敗しました。画面を更新して再度設定してください。","type": "success"});

        }
    },
  async changeTab(this:any  , tn :number){
       this.currentTabNum = tn;

        await this.changeView();
    
   },
   async updateViewAndFetchData (this:any){

       if(this.showEditMode){
          
        const param:any = new URLSearchParams(window.location.search);   
           if( this.selectedEditView &&  this.selectedEditView.src){
            if(param.get("chartSql")){
                this.editViewURL = this.replacePathParamOnece(this.addEditViewQuery(  this.selectedEditView.src ) )+ "&q="+param.get("chartSql").replaceAll ( '+','%2B' )  ;
            } else{
                 this.editViewURL = this.replacePathParamOnece(this.addEditViewQuery(  this.selectedEditView.src ) );
            }
            }
       }

      // this.clearViewAll();
       // this.tabs = [] ;
       if(!this.setting.tabs){
           return true;
       }
       if(!  this.setting.tabs[this.currentTabNum]){
           return true;
       }
             
       try{
       
        this.$emit('show-loader',true);   
        
        this.Sql = await  ( window as any ).getSql();
         if (
            this.setting.tabs[this.currentTabNum].sensorids &&
            this.setting.tabs[this.currentTabNum].sensorids.length!==0 ){
            
            
            if (this.inLoading == false ){
                this.inLoading = true; 

                const needFirstData =  ( undefined == this.setting.tabs[this.currentTabNum].needFirstData )?false :this.setting.tabs[this.currentTabNum].needFirstData ;
                const needAggregateData =  ( undefined == this.setting.tabs[this.currentTabNum].needAggregateData )?true :this.setting.tabs[this.currentTabNum].needAggregateData ;
                const needRawData =  ( undefined == this.setting.tabs[this.currentTabNum].needRawData )?false :this.setting.tabs[this.currentTabNum].needRawData ;
                const needLatestData =  ( undefined == this.setting.tabs[this.currentTabNum].needLatestData )?false :this.setting.tabs[this.currentTabNum].needLatestData ;
                const RequestAllAtOnce = ( undefined == this.setting.tabs[this.currentTabNum].RequestAllAtOnce )?false :this.setting.tabs[this.currentTabNum].RequestAllAtOnce ;
            
            const resp = await this.$refs.dateselector.fetchData(  
                 this.setting.tabs[this.currentTabNum].sensorids ,
                 RequestAllAtOnce,
                 needFirstData,
                 needAggregateData,
                 needRawData,
                 needLatestData
             );  
               
            if (resp  && resp.flash){
         
                this.$emit( 'show-flash', resp.flash);
                this.$emit('show-loader',false);  
               }
           
           this.inLoading =false ;
            } else {
                
          this.$emit( 'show-flash', {"message":"画面の読み込み中","type": "warning"});
       
                return;
            }
          
            }
   
       // await this.Sql.executeAsync();
        this.$emit('show-loader',false);  
        this.updateView( this.currentTabNum );
       
        // await this.reloadViewAll();
         }catch (e){
            console.log(e);
            this.$emit( 'show-flash', {"message":"ヒント:１つのタブで表示するデータ量は多過ぎませんか？","type": "warning"});
        }
   },
   async reloadViewAll(this:any){  

        for (  var i=0 ; i < this.setting.tabs.length ; i++  ){
        await  async function () {  return new Promise(function(resolve){ setTimeout(resolve,30); }) } ();
            this.updateView(i);
        }
   
    },

    async updateView (this :any , tn:number){
    
        this.tabs[tn]  = []  ;
        
        // constsensorIDs = this.setting.tabs[ tn ].sensorids.join(","); 
        
        for (  var k=0 ; k < this.setting.tabs[ tn ].visualizations.length ; k++  ){
            
            const o = this.setting.tabs[ tn ].visualizations[k];
            
            if ( o.type==='iframe'  ){
                      
                var html = await this.createTagString (  o  , tn ) ;
               // const conpressed = await Compression.Compression.compressString( o.src );
           
           this.tabs[tn]  .push ( { type:"iframe", html : html , position: o.position } );
            
            }else if ( o.type==='metric' ){

                // const src =this.replacePathParam( o.src.replace(/\r?\n/g, '') );                
                const src = await this.compressSrc( this.replacePathParam( o.src ));
                  
                this.tabs[tn] .push ( {   title : o.title ,  type:"metric" ,src: src , position: o.position } );

            }else if ( o.type==='customdateselector' ){
                
                const src = o.src;
                this.tabs[tn] .push ( {   title : o.title ,  type:"customdateselector" ,src: src , position: o.position } );
                       
            }else if ( o.type==='jsfield' ){
                // console.log(this.replacePathParam( o.src.replace(/\r?\n/g, '') ));
            
                const src = this.replacePathParam( o.src.replace(/\r?\n/g, '') );
                this.tabs[tn] .push ( {   title : o.title ,  type:"jsfield" ,src: src , position: o.position } );
            
            }else if ( o.type==='image' ){
                
                const src = this.replacePathParam( o.src.replace(/\r?\n/g, '') );
                this.tabs[tn] .push ( {   title : o.title , type:"image" , src: src , position: o.position } );
            
            }else if ( o.type==='imagev4' ){
                
                const src = this.replacePathParam( o.src.replace(/\r?\n/g, '') );
                this.tabs[tn] .push ( {   title : o.title , type:"imagev4" , src: src , position: o.position } );
            
            }else if ( o.type==='map' ){

                const src = this.replacePathParam( o.src.replace(/\r?\n/g, '') );
                this.tabs[tn] .push ( {   title : o.title , type:"map" , src: src , position: o.position } );
            }
            else if ( o.type==='plateauview' ){

                const src = this.replacePathParam( o.src.replace(/\r?\n/g, '') );
                this.tabs[tn] .push ( {   title : o.title , type:"plateauview" , src: src , position: o.position } );
            }
            
            /*var p= this.tabs.pop();
            for( ;p;  ){
                
                p= this.tabs.pop();
            }*/
        } 
   }

 }
 });
